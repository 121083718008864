import { getCMSSettings, getCMSSlot } from "../../../../tools/cmsBlockSettings";
import { makeStyles } from "@material-ui/styles";
import theme from "../../../theme";
import { Grid } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import {
	elevateClickNotification,
	elevateGeneralRecommendations,
	elevateLandingPage
} from '@tools/elevateClient';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ElevateListing from "../../../page/search/elevateListing";
import { useRouter } from "next/router";
import {
	getFastDelivery,
	getMainVariant, getMarketingLabel,
	getProductImage,
	getProductVariants, getStock, getUUID,
	prepareFacets,
	preventFloatRoundingIssues
} from '@functions/elevateOps';
import SlickWrapper from "../../../slick/productSlider";
import ProductItem from "../../../products/item";
import Image from "react-storefront/Image";
import urlNormalizer from "../../../../tools/urlNormalization";
import useSessionStore from '@services/stores/SessionStore';

const useStyles = makeStyles(
	{
		wrapper: {
			paddingTop: 40,
			paddingBottom: 40,
			width: '100%',
			margin: 'auto',
		},
	},
	{ name: 'VoyadoProductBlock' }
);

export default function VoyadoProductBlock({ block, slots, breadcrumbs, category }) {
    const config = useSessionStore((state) => state.session.config);
    const router = useRouter();
    const { query } = router;
	const [params, setParams] = useState({ ...query, initial: true });
    const classes = useStyles();
	const CMSSettings = getCMSSettings(block, 0);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [elevateData, setElevateData] = useState({facets: [], productGroups: [], sort: {selected: 'RELEVANCE', options: []}, totalHits: 0});
    const productBlock = getCMSSlot('voyadoProductBlock', slots);
    const type = productBlock?.translated?.config?.type.value;

    useEffect(async () => {
        if(type === 'landing-page') {
            getVoyadoLandingPage();
        } else if (type === 'recommendations') {
			getVoyadoRecommendations();
        }
    }, [router]);

    useEffect(() => {
		setParams({ ...query, initial: true });
	}, [query]);

	useEffect(async () => {
		let routerData = {
			pathname: router.asPath.split('?')[0],
			query: {
				...params,
			},
			shallow: true
		};

		if (routerData.query.page === 1) {
			delete routerData.query.page;
		}

		if (routerData.query.slug) {
			delete routerData.query.slug;
		}

		delete routerData.query.initial;

		if (!params.initial) {
			router.push(routerData);
		}
	}, [params]);

	async function onClick(product) {
		await elevateClickNotification(
			product.ticket,
			config.elevateMarket,
			config.language.languageCode,
			isMobile ? 'mobile' : 'desktop'
		);
	}

	const products = useMemo(() => {
		let products = [];
		elevateData?.productGroups?.forEach((productSuggestions) => {
			products.push(productSuggestions.products[0]);
		});

		return products;
	}, [elevateData]);

    async function getVoyadoLandingPage() {
        let { q, page, order, slug, initial, ...filters } = router.query;
		
        const result = await elevateLandingPage(
            productBlock?.translated?.config?.pageUrl?.value,
            order || 'RELEVANCE',
            prepareFacets(filters),
            page,
            config.elevateMarket,
            config.language.languageCode,
            isMobile ? 'mobile' : 'desktop');

		let primaryList = {};
		let recommendationList = {};
		result.primaryList ? primaryList = result.primaryList : {};
		result.recommendationLists ? recommendationList = result.recommendationLists[0] : {};
        setElevateData({...elevateData, ...recommendationList, ...primaryList});
    }

	async function getVoyadoRecommendations() {
		const result = await elevateGeneralRecommendations(
			productBlock?.translated?.config?.algorithm.value,
			productBlock?.translated?.config?.limit.value !== '' ? productBlock?.translated?.config?.limit.value : 12,
			config.elevateMarket,
            config.language.languageCode,
            isMobile ? 'mobile' : 'desktop');

		let recommendationList = {};
		result.recommendationLists ? recommendationList = result?.recommendationLists[0] : {};
		setElevateData({...elevateData, ...recommendationList});
	}

    function isSlider() {
		return !!productBlock?.config?.slider?.value;
	}

	function renderProducts(sizes) {
		return products.length > 0 ? products.map((product, key) => {
			let mainVariant = getMainVariant(product);
			let mainImage = getProductImage(product);

			return <ProductItem
				id={getUUID(mainVariant)}
				productNumber={mainVariant.key}
				name={product.title}
				manufacturer={product.brand}
				link={urlNormalizer(product.link, router.basePath)}
				prices={{
					price: mainVariant.sellingPrice,
					listPrice: mainVariant.listPrice,
					isOnSale: mainVariant.sellingPrice < mainVariant.listPrice,
					discount: mainVariant.sellingPrice < mainVariant.listPrice ? {
						amount: mainVariant.sellingPrice - mainVariant.listPrice,
						percentage: preventFloatRoundingIssues(mainVariant.sellingPrice, mainVariant.listPrice)
					} : undefined
				}}
				image={<Image {...mainImage}
				  alt={product.title}
				  className={classes.productImage}
				  lazyOffset={400}
				  notFoundSrc={'/images/placeholder/product.jpg'}
				  contain/>}
				list="Search results"
				variants={getProductVariants(product) ? mainVariant.label : false}
				stock={getStock(mainVariant)}
				fastDelivery={getFastDelivery(mainVariant)}
				labels={getMarketingLabel(mainVariant)}
				available={mainVariant.inStock}
				addToCart={'minimal'}
				isOutlet={product.title.indexOf('OUTLET') >= 0}
				position={key + 1}
				clickCallback={onClick.bind(this, product)}
				elevateTicket={mainVariant.ticket}
				isMobile={isMobile}
				customClasses={{item: classes.productItem}}
				lazyHydrate={false}
				sizes={sizes}
				key={key}
			/>
		}) : '';
	}

    return <Grid
		container
		direction="row"
		justify="center"
		alignItems="stretch"
		spacing={1}
		className={classes.wrapper}
		style={{
			...CMSSettings.styles
		}}
	>
			{type === 'landing-page' &&
				(isSlider() ?
					<Grid xs={12} item>
						<SlickWrapper>
							{renderProducts({xs: 12, sm: 12, md: 12, lg: 12, xl: 12})}
						</SlickWrapper>
					</Grid>
					:
					<div className={classes.wrapper}>
						<ElevateListing router={router} params={params} setParams={setParams} {...elevateData} />
					</div>)
			}

			{ type === 'recommendations' &&
				(isSlider() ?
					<Grid xs={12} item>
						<SlickWrapper>
							{renderProducts({xs: 12, sm: 12, md: 12, lg: 12, xl: 12})}
						</SlickWrapper>
					</Grid>
					:
					renderProducts())
			}
	</Grid>
}
