import { Grid, Typography } from '@material-ui/core';
import { TrackingContext } from 'components/providers/tracking';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import urlNormalizer from 'tools/urlNormalization';
import Image from "next/image";
import { getProductPrices } from "../../../../functions/productOps";
import { elevateClickNotification } from "tools/elevateClient";
import useSessionStore from '@services/stores/SessionStore';

export default function SearchResultProduct({ props }) {
	const formatPrice = useSessionStore((state) => state.methods.formatPrice);
	const { product, index, classes, config, isMobile, focus } = props;
	const { actions } = useContext(TrackingContext);
	const router = useRouter();
	const productNumber = product.productNumber || product.key;
	const prices = getProductPrice();

	async function onProductClick() {
		if(product.ticket) {
			let mainVariant = getMainVariant(product);

			actions.productClick({
				id: product.key,
				name: product.title,
				variant: mainVariant.label,
				price: product.sellingPrice,
				list: 'Autocomplete search',
				position: index + 1
			});

			await elevateClickNotification(
				product.ticket,
				config.elevateMarket,
				config.language.languageCode,
				isMobile ? 'mobile' : 'desktop'
			);
		} else {
			actions.productClick({
				id: productNumber,
				name: product.name,
				variant: product.variant || null,
				price: prices.price,
				list: 'Autocomplete search',
				position: index + 1
			});
		}
	}

	function getProductImage() {
		if(product.cover?.media?.url) {
			return {
				src: product.cover.media.url,
				width: product.cover.media.metaData.width,
				height: product.cover.media.metaData.height,
			}
		}

		let productImages = product.imageInfo?.images;

		if(!productImages || productImages.length <= 0) {
			return { src: '/images/placeholder/product.jpg', width: 550, height: 510 };
		}

		let imageSource = productImages.find((item) => {
			return item.custom.type === 'list';
		})?.sources[0] || productImages[0]?.sources[0];

		if(productImages.length > 1) {
			productImages.forEach((image) => {
				if (image['sources'][0].width === 550) {
					imageSource = image['sources'][0];
				}
			});
		}

		const width = (imageSource.width || 550);
		const height = (imageSource.height || 510);

		return {
			src: imageSource.url,
			width: width,
			height: height,
			aspectRatio: height / width,
		}
	}

	function getProductPrice() {
		if(product.calculatedPrice?.unitPrice) {
			return getProductPrices(product);
		} else {
			let mainVariant = getMainVariant(product);

			return {
				price: mainVariant.sellingPrice,
				listPrice: mainVariant.listPrice > mainVariant.sellingPrice ? mainVariant.listPrice : mainVariant.sellingPrice
			}
		}
	}

	function getMainVariant(product) {
		let mainSku = product.key.split('-')[1];
		let mainVariant = product.variants.find((variant) => {
			return variant.key === mainSku;
		});

		return mainVariant || product.variants[0];
	}


	function getProductUrl(product) {
		if(product.seoUrls) {
			return product.seoUrls.length > 0 ? '/' + product.seoUrls[0].seoPathInfo : 'details/' + product.id
		} else {
			return product.link;
		}
	}

	function getProductName() {
		return product?.translated?.name || product.title;
	}

	return (
		<Grid xs={12} sm={6} lg={6} key={`search-results-${index}-${productNumber}`} item>
			<Link href={urlNormalizer(getProductUrl(product), router.basePath)}>
				<a className={[classes.product, focus ? 'focus' : ''].join(' ')} onClick={onProductClick.bind(this, product, index)}>
					<div className={classes.product__image}>
						<Image {...getProductImage()} unoptimized/>
					</div>
					<div className={classes.product__info}>
						<Typography
							className={classes.product__name}
							variant="body1"
							dangerouslySetInnerHTML={{ __html: getProductName() }}
						/>
						{prices.price > 0 ? <div className={classes.product__prices}>
							{prices.listPrice > prices.price ? (
								<>
									<Typography
										variant="body1"
										className={[classes.product__price, 'special'].join(' ')}
									>
										{formatPrice(prices.price)}
									</Typography>
									<Typography variant="body1" className={[classes.product__price, 'old'].join(' ')}>
										{formatPrice(prices.listPrice)}
									</Typography>
								</>
							) : (
								<Typography variant="body1" className={classes.product__price}>
									{formatPrice(prices.price)}
								</Typography>
							)}
						</div> : ''}
					</div>
				</a>
			</Link>
		</Grid>
	);
}
