import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { Grid } from "@material-ui/core";
import MasonryProductItem from 'components/products/masonryItem';
import theme from 'components/theme';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'tools/i18n';
import { getProductUrl, getProductPrices, getIsAvailable, getCategoryBreadcrumb } from 'functions/productOps';
import ProductThumbnail from "./thumbnail";

//----- Masonry Grid Settings ----------------------------------------
const columnWidth = { lg: '33.333%', md: '50%', sm: '100%' };
const gutter = 20;
//--------------------------------------------------------------------

const useStyles = makeStyles(
	() => ({
		masonryGrid: {
			[theme.breakpoints.down('md')]: {
				margin: '0 10px'
			},
			[theme.breakpoints.down('sm')]: {
				margin: '0 20px'
			}
		},
		masonryGridSizer: {
			width: `calc(${columnWidth.lg} - ${(gutter * 2) / 3}px)`,
			[theme.breakpoints.down('md')]: {
				width: `calc(${columnWidth.lg} - ${(gutter * 2) / 3}px)`
			},
			[theme.breakpoints.down('sm')]: {
				width: `calc(${columnWidth.md} - ${(gutter) / 2}px)`
			},
			[theme.breakpoints.down('xs')]: {
				width: columnWidth.sm
			}
		},
		masonryGridItem: {
			marginBottom: '15px',
			width: `calc(${columnWidth.lg} - ${(gutter * 2) / 3}px)`,
			[theme.breakpoints.down('md')]: {
				width: `calc(${columnWidth.lg} - ${(gutter * 2) / 3}px)`
			},
			[theme.breakpoints.down('sm')]: {
				width: `calc(${columnWidth.md} - ${(gutter) / 2}px)`
			},
			[theme.breakpoints.down('xs')]: {
				width: columnWidth.sm
			}
		}
	}),
	{ name: 'MasonryProductGrid' }
);
function MasonryProductGrid({ productListing, breadcrumbs, products, list, paramsHash }) {
	const env = process.env.NEXT_PUBLIC_ENVIRONMENT;
	const scope = process.env.NEXT_PUBLIC_SHOPWARE_DOMAIN_SCOPE;
	const { t } = useTranslation();
	// const router = useRouter();
	const classes = useStyles();
	let Masonry;
	let masonryGrid;
	const collection = list;
	const collectionChanged = useHasChanged(collection)

	useEffect(async () => {
		// Masonry 1st load
		masonryGrid = await initMasonry(getGridElementId(collection));
		masonryGrid.reloadItems();
		masonryGrid.layout();
	}, []);

	useEffect( async() => {
		if (!!masonryGrid?.element) {
			masonryGrid.destroy();
		}

		masonryGrid = await initMasonry(getGridElementId(collectionChanged.new || collection));
		masonryGrid.reloadItems();
		masonryGrid.once('layoutComplete', function() {
			requestAnimationFrame(() =>
				masonryGrid.layout()
			);
		});
	}, [products, collection]);

	function getGridElementId(newList) {
		return 'masonry-grid-' + newList + '-' + paramsHash;
	}
	async function initMasonry(gridElementId) {
		Masonry = !Masonry ? require('masonry-layout') : Masonry;
		const gridElement = await document.getElementById(gridElementId);
		const masonryGrid = new Masonry(gridElement, {
			columnWidth: '.masonry-grid-sizer',
			itemSelector: '.masonry-grid-item',
			percentPosition: true,
			horizontalOrder: false,
			gutter
		});
		masonryGrid.reloadItems();
		masonryGrid.layout();
		// For debugging
		if (window) {
			window.masonryGridDebug = masonryGrid;
		}

		return masonryGrid;
	}

	function getImage(product) {
		if(product.translated?.customFields?.masonryMedia) {
			return {
				media: product.translated?.customFields?.masonryMedia
			}
		} else if (product.cover) {
			return product.cover;
		}
	}

	return (
		<>
			<div style={{ width: '100%', minHeight: 829 }}>
				<div id={getGridElementId(collectionChanged.new || collection)} className={classes.masonryGrid}>
					<div className={[classes.masonryGridSizer, 'masonry-grid-sizer'].join(' ')} />
					{products.length > 0 ? (
						products.map((product, key) => (
							<div
								id={`masonry-grid-item-wrapper-${product.id + key}`}
								className={[classes.masonryGridItem, 'masonry-grid-item'].join(' ')}
								key={paramsHash + product.productNumber + key}
							>
								<MasonryProductItem
									id={`masonry-grid-product-item-${product.id + key}`}
									productNumber={product.productNumber}
									name={product.translated.name}
									link={getProductUrl(product)}
									price={getProductPrices(product).price}
									categoryBreadcrumb={breadcrumbs ? getCategoryBreadcrumb(breadcrumbs) : []}
									image={<ProductThumbnail image={getImage(product)} alt={product.translated?.name} size={550} index={key}/>}
									list={list}
									manufacturer={product.manufacturer ? product.manufacturer.translated.name : ''}
									available={getIsAvailable(product)}
									position={key + 1}
									key={`masonry-grid-product-item-${paramsHash + product.id + key}`}
									propertyIds={product.propertyIds}
								/>
							</div>
						))
					) : (
						<Grid xs={12} item>
							<Alert severity="warning">{t('empty-category')}</Alert>
						</Grid>
					)}
				</div>
			</div>
		</>
	);
}
const useHasChanged= (val) => {
	const prevVal = usePrevious(val)
	return {
		hasChanged: prevVal !== val,
		previous: prevVal,
		new: val
	}
}

const usePrevious = (value) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}
export default MasonryProductGrid;
