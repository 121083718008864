import React, {useContext, useState} from 'react';
import {Grid, Button} from "@material-ui/core";
import Link from "next/link";
import {makeStyles} from "@material-ui/styles";
import {TrackingContext} from "../providers/tracking";
import {useTranslation} from "../../tools/i18n";
import LazyHydrate from "../../tools/lazyHydrate";
import ShoppingBag from "../../public/images/icons/shopping-bag.svg";
import theme from "../theme";
import { elevateAddToCartNotification } from "../../tools/elevateClient";
import useSessionStore from '@services/stores/SessionStore';
import useCartStore from '@services/stores/CartStore';

const useStyles = makeStyles(() => ({
	item: {
		display: 'flex',
		'.autoWidth &': {
			maxWidth: 'none',
			flexGrow: 1
		},
		'&:hover': {
			'& $purchaseButtonMinimal': {
				backgroundColor: theme.palette.primary.background,
			}
		},
		'&:last-of-type': {
			boxShadow: 'none'
		},
	},
	link: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		flexGrow: 1,
		paddingBottom: 10,
		textDecoration: 'none',
		backgroundColor: theme.palette.primary.background,
		color: 'inherit',
		[theme.breakpoints.up('md')]: {
			paddingBottom: 5,
		}
	},
	imageWrapper: {
		position: 'relative',
		overflow: 'hidden',
		width: 80,
		maxWidth: 80,
		minWidth: 80,
		marginRight: 10,
		[theme.breakpoints.up('md')]: {
			width: 100,
		}
	},
	variantList: {
		display: 'flex',
		position: 'absolute',
		left: 6,
		bottom: 6,
		padding: 0,
		margin: 0,
		fontSize: 12,
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		listStyle: 'none'
	},
	variant: {
		width: 'auto',
		marginRight: 2,
		padding: '4px 6px',
		borderRadius: 3,
		backgroundColor: '#eaeaea'
	},
	selectedVariant: {
		backgroundColor: '#cbf3e4 !important'
	},
	infoWrapper: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		flexGrow: 1
	},
	info: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		flexGrow: 1,
		width: 100,
		overflow: 'hidden',
		padding: '0px 10px 0px 10px',
		[theme.breakpoints.up('md')]: {
			padding: '8px 8px 12px 8px',
		}
	},
	name: {
		margin: 0,
		fontSize: 13,
		fontWeight: 300,
		color: '#000',
		textAlign: 'left',
		fontSmoothing: 'auto',
		textOverflow: 'ellipsis',
	},
	prices: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		alignItems: 'center',
		marginTop: 4,
	},
	price: {
		fontSize: 13,
		color: '#666',
		'&.special': {
			color: '#ca0d0d',
			fontWeight: 600
		},
		'&.old': {
			marginLeft: 10,
			textDecoration: 'line-through',
			color: '#999'
		}
	},
	discountBubble: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '2px 7px',
		marginLeft: 8,
		fontSize: 11,
		fontWeight: 600,
		borderRadius: 0,
		lineHeight: '22px',
		backgroundColor: theme.palette.primary.background,
		border: '1px solid #000',
		color: '#000',
		pointerEvents: 'none',
		[theme.breakpoints.up('md')]: {
			fontSize: 10,
		}
	},
	purchaseTouchRipple: {
		backgroundColor: 'rgba(0,0,0,0.5)'
	},
	purchaseButton: {
		alignSelf: 'center',
		padding: '6px 12px',
		minWidth: '95px',
        border: '1px solid #212121',
        fontSize: 12,
        color: theme.palette.primary.color,
		backgroundColor: theme.palette.primary.background,
		borderRadius: 0,
        fontWeight: 400,
		letterSpacing: 0.5,
		whiteSpace: 'nowrap',
		'&:hover': {
			backgroundColor: theme.palette.primary.color,
			color: theme.palette.primary.background
		},
		'&:disabled': {
			color: theme.palette.primary.color,
		},
		[theme.breakpoints.up('md')]: {
			marginLeft: 'auto',
			padding: '8px 24px',
		}
	},
	purchaseButtonMinimal: {
		margin: 'auto',
		padding: '12px',
		maxWidth: '44px',
		minWidth: 'unset',
	}
}), {name: 'ProductItemHorizontal'});

export default function ProductItemHorizontal({
		id,
		productNumber,
		name,
		image,
		link,
		prices,
		manufacturer,
		variants = false,
		available = true,
		stock = 0,
		list,
		categoryBreadcrumb,
		sizes = {lg: 3, md: 4, sm: 6, xl: 3, xs: 6},
		position,
		labels = [],
		addToCart = false,
		like = false,
		fastDelivery = false,
		customClasses = {},
		customAttributes = {
			item: {}
		},
		lazyHydrate = true,
		elevateTicket = false,
		isMobile = false,
		clickCallback
	}) {
	const classes = useStyles({classes: customClasses});
	const {t} = useTranslation();
	const {actions} = useContext(TrackingContext);
	const session = useSessionStore((state) => state.session);
	const cartMethods = useCartStore((state) => state.methods);
	const [loading, setLoading] = useState(false);
	const formatPrice = useSessionStore((state) => state.methods.formatPrice);

	function onClickTracking() {
		actions.productClick({
			id: productNumber,
			name: name,
			price: prices.price,
			discount: Math.abs(prices?.discount?.amount),
			brand: manufacturer,
			category: categoryBreadcrumb,
			list,
			variant: variants,
			quantity: 1
		});

		if(typeof clickCallback !== 'undefined' ) {
			clickCallback(productNumber);
		}
	}

	async function purchaseTracking() {
		actions.addToCart({
			id: productNumber,
			name: name,
			price: prices.price,
			discount: Math.abs(prices?.discount?.amount),
			brand: manufacturer,
			category: categoryBreadcrumb,
			list,
			variant: variants,
			quantity: 1
		});

		if(elevateTicket) {
			await elevateAddToCartNotification(
				elevateTicket,
				session.config.elevateMarket,
				session.config.language.languageCode,
				isMobile ? 'mobile' : 'desktop'
			)
		}
	}

	async function handlePurchase(e) {
		e.preventDefault();
	    e.nativeEvent.preventDefault();

		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();

		setLoading(true);
		await cartMethods.addToCart({product: id, quantity: 1});
		setLoading(false);

		await purchaseTracking();
	}

	function renderItem() {
		return (
			<Link href={link}>
				<a className={classes.link} onClick={onClickTracking} {...customAttributes.item}>
					<div className={classes.imageWrapper}>
						{image}

						{!available ? <span className={[classes.discountBubble, 'discountBubble'].join(' ')}>{t('out-of-stock')}</span> : ''}
					</div>

					<div className={classes.infoWrapper}>
						<div className={classes.info}>
							<h4 className={classes.name} dangerouslySetInnerHTML={{__html: name}}/>

							<div className={classes.prices}>
								{prices.isOnSale ?
									<>
										<span className={[classes.price, 'special'].join(' ')}>{formatPrice(prices.price)}</span>
										<span className={[classes.price, 'old'].join(' ')}>{formatPrice(prices.listPrice)}</span>
									</> : <span className={classes.price}>{formatPrice(prices.price)}</span>
								}

								{prices.discount && false ? <span className={[classes.discountBubble, 'discountBubble'].join(' ')}>{Math.floor(prices.discount.percentage)}%</span> : ''}
							</div>
						</div>
					</div>

					{addToCart ? (
						addToCart === 'minimal' ? <Button className={[classes.purchaseButtonMinimal, 'add-to-cart'].join(' ')} TouchRippleProps={{classes: {rippleVisible: classes.purchaseTouchRipple}}} onClick={(event) => {!loading && handlePurchase(event); return false;}}>
							<ShoppingBag width={28} height={28} alt={t('buy-short')}/>
						</Button> : <Button className={[classes.purchaseButton, 'add-to-cart'].join(' ')} onClick={(event) => {!loading && handlePurchase(event); return false;}} disabled={loading}>
							{t('buy-short')}
						</Button>
					) : ''}

				</a>
			</Link>
		);
	}

	return (
		<Grid
			item={true}
			{...sizes}
			classes={{
				root: classes.item
			}}
		>
			{lazyHydrate ?
				<LazyHydrate id={"ProductItem" + productNumber + position} on="visible">
					{renderItem()}
				</LazyHydrate> : renderItem()
			}
		</Grid>
	);
}
